<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp inventory_data_table">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            v-model="selected"
            show-select
            :headers="headers"
            :items="dataForm.records"
            :search="search"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search" :class="`${!selected.length ? 'not_selected' : ''}`">
                <v-col cols="12" md="6" class="inventory-col-left">
                  <div class="inventory-actions">
                    <button
                      :disabled="!selected.length" 
                      :class="`${!selected.length ? 'actions-disabled' : ''}`"
                      class="dialog-default-button data-table-actions-size dialog-bg-grey-button switch-btn add-user-btn button-hover" 
                      @click="openDeleteDialog(selected, 'Delete Inventory', 'You are about to delete an inventory from the current system. The inventory will no longer be registered and controlled.')"
                    >
                      Delete Inventory
                      <img src="@/assets/tables/inventory_delete.svg" alt="Delete Inventory">
                    </button>
                    <button
                      :disabled="!selected.length"
                      :class="`${!selected.length ? 'actions-disabled' : ''}`" 
                      class="dialog-default-button data-table-actions-size dialog-bg-grey-button switch-btn add-user-btn button-hover" 
                      @click="exportToPDF"
                    >
                      Export QR Code
                      <img src="@/assets/tables/qr_code_icon.svg" alt="Export QR Code">
                    </button>
                  </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="6" class="inventory-col-right">
                  <div class="total-heading">
                    <h1>Total Inventory: {{ dataForm?.total ? dataForm.total : 0 }}</h1>
                  </div>
                  <div class="switch-component switch-custom">
                    <button class="dialog-default-button data-table-add-size inventory-add-size dialog-bg-grey-button switch-btn add-user-btn button-hover" @click="openDialogAddInventory">
                      Add New Inventory
                    </button>
                    <v-text-field
                      v-model="search"
                      placeholder="Search..."
                      class="search-site"
                      prepend-inner-icon="fas fa-search"
                      rounded
                      outlined
                      filled
                    ></v-text-field>
                    <v-btn
                      class="switch-btn button-hover"
                      icon
                      @click="openDialogFilterInventory"
                      title="Filter Inventory"
                    >
                      <img src="@/assets/tables/filter-icon.svg" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="selected?.length">
                <p class="quantity_selected">({{ selected.length }} selected)</p>
              </v-row>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td 
                class="text-center" 
                :style="
                  `background-color: ${ASSET_STATUS_COLORS[item.status]};
                   color: ${item.status === 'offline' ? '#636366' : '#ffffff'}!important;
                  `
                ">
                  {{ capitalizeFirstLetter(item.status) }}
                </td>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <span>{{ typeName[item.type] }}</span>
            </template>
            <template v-slot:[`item.software_version`]="{ item }">
              <span>{{ item.software_version ? item.software_version : '✕' }}</span>
            </template>
            <template v-slot:[`item.device_key`]="{ item }">
              <div v-if="item.device_key" class="d-flex">
                <span 
                  @click="copyDeviceKey(item.device_key)" 
                  class="cursor-pointer mr-3 icon-action" 
                  title="Click to copy"
                >
                  <i class="far fa-copy"></i>
                </span>
                <span>{{ showDeviceKey(item.device_key) }}</span>
              </div>
            </template>
            <template v-slot:[`item.inventory_status`]="{ item }">
              <span>{{ item.inventory_status ? capitalizeFirstLetter(item.inventory_status) : "−−" }}</span>
            </template>
          </v-data-table>
          <div class="data-table-paging">
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogAddInventory"
        persistent
        content-class="custom-content-modals custom-scroll-modals"
        max-width="756"
      >
        <v-card>
          <v-card-title>Add New Inventory</v-card-title>
          <v-card-text class="custom-asset-card">
            <v-form
              ref="addForm"
              @submit.prevent="submitAdd"
              autocomplete="off"
            >
              <div class="scroll_dialog_form">
                <v-row no-gutters>
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Device Type</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="deviceTypeList"
                      v-model="deviceType"
                      :rules="deviceTypeRules"
                      item-text="name"
                      item-value="key"
                      :class="`${deviceType ? 'custom-hidden-input' : ''}`"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Device Type"
                      :validate-on-blur="true"
                      @change="updateSubtypeList"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Sub Type</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-select
                      :items="subTypeList"
                      v-model="subType"
                      item-text="title"
                      :rules = "subTypeRules"
                      required
                      filled
                      :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                      :class="`${subType ? 'custom-hidden-input' : ''}`"
                      append-icon="fas fa-caret-down"
                      placeholder="Select Sub Type"
                      :validate-on-blur="true"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Brand</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="brand"
                      :rules="brandRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Brand"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Model</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="model"
                      :rules="modelRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Model"
                      filled
                      :validate-on-blur="true"
                      @input="handleInputModel"
                      @blur="closeAutocomplete"
                      @keydown.down="navigateOptions"
                      @keydown.up="navigateOptions"
                      @keyup.enter="selectHighlightedOption"
                    ></v-text-field>
                    <template v-if="showAutocomplete">
                      <div class="autocomplete-items">
                        <div 
                          class="model-item" 
                          v-for="(option, index) in filteredOptions" 
                          :key="index" 
                          @click="selectModelOption(option)"
                          :class="{ 'highlighted': index === highlightedIndex }"
                        >
                          {{ option }}
                        </div>
                      </div>
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Software Version</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="softwareVersion"
                      :rules="softwareVersionRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Software Version"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Batch</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-menu
                      ref="menuBatchDate"
                      v-model="menuBatchDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="332"
                      min-width="auto"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="batchDateText"
                          :rules="batchRules"
                          append-icon="fas fa-caret-down"
                          placeholder="Select Month/Year of Batch"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="date-picker-text-field date-picker-batch"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="batchDate"
                        no-title
                        type="month"
                        :dark="true"
                        @change="changedMonth"
                        class="month-picker-table custom_date_picker_actions"
                      >
                        <v-btn :class="`${getActiveCurrentMonth() ? 'active_current_month' : ''}`" class="date_picker_outline_btn" text @click="getCurrentMonth">
                          Current Month
                        </v-btn>
                        <v-btn class="date_picker_grey_btn" text @click="menuBatchDate = false">
                          Cancel
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label">
                    <span>Quantity</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-text-field
                      v-model="quantity"
                      :rules="quantityRules"
                      required
                      autocomplete="off"
                      placeholder="Enter Quantity"
                      filled
                      :validate-on-blur="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pt-2">
                  <v-col cols="12" md="3" class="modal-label-desc">
                    <span>SN</span>
                  </v-col>
                  <v-col cols="12" md="9">
                    <p class="sn-desc">Serial Number will be generated automatically and incrementally based on <br /> Device Category, Device Type, Batch Number</p>
                  </v-col>
                </v-row>
              </div>
              <v-row no-gutters class="mt-3 pt-2">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogAddInventory"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    CONFIRM
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFilterInventory"
        persistent
        content-class="custom-content-modals"
        max-width="700"
      >
        <v-card>
          <v-card-title class="v-card__title-filter">Filter</v-card-title>
          <v-card-text class="custom-asset-card v-card__text-filter">
            <v-form
              ref="filterInventoryForm"
              @submit.prevent="submitFilter"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Inventory Category</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryCategory" class="cursor-pointer" type="checkbox" id="type-sc" name="type-sc" value="sc" />
                        <label for="type-sc">eBox</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryCategory" class="cursor-pointer" type="checkbox" id="type-pm" name="type-pm" value="pm" />
                        <label for="type-pm">Smart Meter</label>
                      </div>
                    </v-col>

                    <v-col cols="4" style="visibility: hidden;">
                      <div class="option-item">
                        <input class="cursor-pointer" type="checkbox" id="type-hidden" />
                        <label for="type-hidden">Hidden</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryCategory" class="cursor-pointer" type="checkbox" id="type-pv" name="type-pv" value="pv" />
                        <label for="type-pv">PV Inverter</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryCategory" class="cursor-pointer" type="checkbox" id="type-bess" name="type-bess" value="bess" />
                        <label for="type-bess">BESS</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryCategory" class="cursor-pointer" type="checkbox" id="type-evse" name="type-evse" value="evse" />
                        <label for="type-evse">EVSE</label>
                      </div>
                    </v-col>          
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Inventory Status</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryStatuses" class="cursor-pointer" type="checkbox" id="registered-status" name="registered-status" value="registered" />
                        <label for="registered-status">Registered</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryStatuses" class="cursor-pointer" type="checkbox" id="unregistered-status" name="unregistered-status" value="unregistered" />
                        <label for="unregistered-status">Unregistered</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedInventoryStatuses" class="cursor-pointer" type="checkbox" id="deregistered-status" name="deregistered-status" value="de-registered" />
                        <label for="deregistered-status">De-registered</label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Batch</p>
                  <v-row no-gutters class="checkboxes-row commissioned-date-row">
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p>From</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-menu
                        ref="batchDateMenu"
                        v-model="batchDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="332"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="batchDateTextFrom"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="fas fa-caret-down"
                            class="date-picker-text-field"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          flat
                          v-model="batchDateRange"
                          no-title
                          range
                          width="auto"
                          first-day-of-week="0"
                          type="month"
                          :dark="true"
                          class="month-picker-table"
                          @input="closeBatchDateMenu"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p style="margin-left: 13px;">To</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-text-field
                        v-model="batchDateTextTo"
                        readonly
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        @click="openBatchDateMenu"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">SN Creation Date</p>
                  <v-row no-gutters class="checkboxes-row commissioned-date-row">
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p>From</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-menu
                        ref="snCreationDateMenu"
                        v-model="snCreationDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="332"
                        min-width="auto"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="snCreationDateTextFrom"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="fas fa-caret-down"
                            class="date-picker-text-field"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          flat
                          v-model="snCreationDateRange"
                          no-title
                          range
                          width="auto"
                          first-day-of-week="0"
                          type="date"
                          :dark="true"
                          class="customize_commissioned_date_picker"
                          @input="closeSnCreationDateMenu"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p style="margin-left: 13px;">To</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-text-field
                        v-model="snCreationDateTextTo"
                        readonly
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        @click="openSnCreationDateMenu"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters class="filter-buttons-row alarm-buttons-row">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogFilterInventory"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="resetDialogFilterInventory"
                  >
                    RESET
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    APPLY
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="deleteInventory()"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { inventoryService } from "@/services";
import moment from "moment";
import jsPDF from 'jspdf';
import QRCode from 'qrcode-generator';
export default {
  data() {
    return {
      selected: [],
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "ID", value: "id", align: 'center' },
        { text: "Serial Number", value: "sn", align: 'center', sortable: false },
        { text: "Type", value: "type", align: 'center', sortable: false },
        { text: "Sub Type", value: "name", align: 'center', sortable: false },
        { text: "Brand", value: "brand", align: 'center', sortable: false },
        { text: "Model", value: "model", align: 'center', sortable: false },
        { text: "SW Version", value: "software_version", align: 'center', sortable: false },
        { text: "Device Key", value: "device_key", align: 'center', sortable: false },
        { text: "SN Creation Date", value: "created_at", align: 'center' },
        { text: "Inventory Status", value: "inventory_status", align: 'center' },
      ],
      dialogAddInventory: false,
      dialogFilterInventory: false,
      confirmDialog: false,
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      assetItem: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deviceType: "",
      brand: "",
      model: "",
      softwareVersion: "",
      batchDate: "",
      batchDateText: "",
      quantity: null,
      deviceTypeList: [
        {
          key: "sc",
          name: "eBox",
        },
        {
          key: "pv",
          name: "PV Inverter",
        },
        {
          key: "bess",
          name: "BESS",
        },
        {
          key: "evse",
          name: "EVSE",
        },
        {
          key: "pm",
          name: "Smart Meter",
        }
      ],
      typeName: {
        'pv': "PV Inverter",
        'sc': "eBox",
        'bess': "BESS",
        'evse': "EVSE",
        'pm': "Smart Meter"
      },
      subType: "",
      subTypeList: [],
      batchDateMenu: false,
      snCreationDateMenu: false,
      selectedInventoryCategory: [],
      selectedInventoryStatuses: [],
      snCreationDateRange: [],
      batchDateRange: [],
      dataForm: {},
      idsDelete: [],
      menuBatchDate: false,
      deviceTypeRules: [
        (v) =>!!v || "Device Type is required",
      ],
      subTypeRules: [
        (v) =>!!v || "Sub Type is required",
      ],
      brandRules: [
        (v) =>!!v || "Brand is required",
      ],
      modelRules: [
        (v) =>!!v || "Model is required",
      ],
      softwareVersionRules: [
        (v) =>!!v || "Software Version is required",
      ],
      batchRules: [
        (v) =>!!v || "Batch is required",
      ],
      quantityRules: [
        (v) =>!!v || "Quantity is required",
        (v) => !isNaN(parseFloat(v)) && isFinite(v) || "Quantity must be a number",
        (v) => v > 0 || "Quantity must be greater than 0",
        (v) => v <= 50 || "Quantity must be less than 50",
        (v) => Number.isInteger(Number(v)) && /^\d+$/.test(v) || "Quantity must be an integer",
      ],
      modelOptions: ['eBox', 'AT50-11000TM', 'AT50-5048PRO', 'AL32S', 'AL16T', 'SDM239', 'SDM630', 'PM-48100W', 'PM-48200W'],
      showAutocomplete: false,
      highlightedIndex: -1,
    };
  },
  async created() {
    this.getLocalTimeZone();
    await this.getListInventories();
  },
  computed: {
    snCreationDateTextFrom () {
      if(this.snCreationDateRange && this.snCreationDateRange.length > 0){
        return `${this.snCreationDateRange[0] ? moment(this.snCreationDateRange[0]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    snCreationDateTextTo () {
      if(this.snCreationDateRange && this.snCreationDateRange.length > 1){
        return `${this.snCreationDateRange[1] ? moment(this.snCreationDateRange[1]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    batchDateTextFrom () {
      if(this.batchDateRange && this.batchDateRange.length > 0){
        return `${this.batchDateRange[0] ? moment(this.batchDateRange[0]).format("MM/YYYY") : ''}`;
      }
      return null;
    },
    batchDateTextTo () {
      if(this.batchDateRange && this.batchDateRange.length > 1){
        return `${this.batchDateRange[1] ? moment(this.batchDateRange[1]).format("MM/YYYY") : ''}`;
      }
      return null;
    },
    filteredOptions() {
      return this.modelOptions.filter(option =>
        option.toLowerCase().includes(this.model.toLowerCase())
      );
    },
  },
  methods: {
    async getListInventories(filters) {
      await inventoryService.getListInventories(filters).then((res) => {
        if (res?.data?.data) {
          const data = res.data.data;
          if (data?.records?.length) {
            let uniqueModels = [];
            data.records.forEach(inventory => {
              inventory.created_at = inventory?.created_at ? this.MOMENT(inventory.created_at, 'DD-MM-YYYY') : "−−";
              if (inventory.model && !uniqueModels.includes(inventory.model)) {
                uniqueModels.push(inventory.model);
              }
            });
            this.modelOptions = [...new Set([...this.modelOptions, ...uniqueModels])].sort();
          }
          this.dataForm = {...data};
        }
      });
    },
    deleteInventory() {
      if (this.idsDelete?.length) {
        let dataDelete = {
          id: this.idsDelete
        }
        inventoryService
          .deleteInventory(dataDelete)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.selected = [];
            this.closeConfirmDialog();
            this.getListInventories();
            this.apiMessage = "Inventory has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Inventory cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertInventory() {
      this.$refs.addForm.reset();
    },
    openDialogAddInventory() {
      this.dialogAddInventory = true;
    },
    openDialogFilterInventory() {
      this.dialogFilterInventory = true;
    },
    closeDialogAddInventory() {
      this.revertInventory();
      this.batchDate = "";
      this.dialogAddInventory = false;
    },
    resetDialogFilterInventory() {
      this.selectedInventoryCategory = [];
      this.selectedInventoryStatuses = [];
      this.snCreationDateRange = [];
      this.batchDateRange = [];
    },
    closeDialogFilterInventory() {
      this.dialogFilterInventory = false;
    },
    openSnCreationDateMenu() {
      return this.snCreationDateMenu = !this.snCreationDateMenu;
    },
    openBatchDateMenu() {
      return this.batchDateMenu = !this.batchDateMenu;
    },
    openDeleteDialog(itemArray, heading, message) {
      this.idsDelete = itemArray.map(item => item.id);
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.idsDelete = [];
      this.dialogHeading = "";
      this.dialogMessage = "";
      this.confirmDialog = false;
    },
    closeMessage() {
      this.messageDialog = false;
    },
    closeSnCreationDateMenu() {
      if(this.snCreationDateRange && this.snCreationDateRange[0] && this.snCreationDateRange[1]){
        this.snCreationDateMenu = false;
      }else{
        this.snCreationDateMenu = true;
      }
      return this.snCreationDateMenu;
    },
    closeBatchDateMenu() {
      if(this.batchDateRange && this.batchDateRange[0] && this.batchDateRange[1]){
        this.batchDateMenu = false;
      }else{
        this.batchDateMenu = true;
      }
      return this.batchDateMenu;
    },
    changedMonth() {
      return this.batchDateText = moment(this.batchDate).format("MM/YYYY");
    },
    prepareData(type, sub_type, brand, model, software_version, batch, quantity) {
      const data = {
        type: type,
        sub_type: sub_type,
        brand: brand,
        model: model,
        software_version: software_version,
        batch: batch,
        quantity: quantity,
      };
      return data;
    },
    async submitAdd() {
      if (this.$refs.addForm.validate()) {
        const data = this.prepareData(
          this.deviceType,
          this.subType,
          this.brand,
          this.model,
          this.softwareVersion,
          this.batchDate,
          this.quantity,
        );
        await inventoryService
          .storeInventory(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListInventories();
            this.closeDialogAddInventory();
            this.apiMessage = "Inventory has been successfully added";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Inventory cannot be added";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    async submitFilter() {
      if(!(this.selectedInventoryCategory?.length || this.selectedInventoryStatuses?.length || this.batchDateRange?.length || this.snCreationDateRange?.length)) {
        await this.getListInventories();
      } else {
        let filters = {};
        if(this.selectedInventoryCategory?.length) {
          filters.device_type = JSON.stringify(this.selectedInventoryCategory);
        }
        if(this.selectedInventoryStatuses?.length) {
          filters.status = JSON.stringify(this.selectedInventoryStatuses);
        }
        if(this.batchDateRange?.length === 2) {
          filters.batch_start_date = this.batchDateRange[0];
          filters.batch_end_date = this.batchDateRange[1];
        }
        if(this.snCreationDateRange?.length === 2) {
          filters.start_date = this.snCreationDateRange[0];
          filters.end_date = this.snCreationDateRange[1];
        }
        await this.getListInventories(filters);
      }
      this.closeDialogFilterInventory();
    },
    exportToPDF() {
      if(this.selected.length) {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const dataExport = this.selected;
        const qrCodeSize = 30;
        // Size and margin between QR codes
        const marginX = 80;
        const marginY = 20;
        // Number of QR codes per page
        const qrCodesPerPage = 8;
        // The distance between the left QR column and the right QR column
        const columnGap = 10;
        // Calculate the number of pages
        const totalPages = Math.ceil(dataExport.length / qrCodesPerPage);
        for (let page = 0; page < totalPages; page++) {
          // Export from first code to last code on each page
          const startIdx = page * qrCodesPerPage;
          const endIdx = Math.min((page + 1) * qrCodesPerPage, dataExport.length);
          for (let i = startIdx; i < endIdx; i++) {
            const qr = QRCode(0, 'M');
            qr.addData(dataExport[i].sn);
            qr.make();
            const qrImage = qr.createDataURL();
            // Calculate the position for each QR code and Serial Number
            const row = Math.floor((i - startIdx) / 2);
            const col = (i - startIdx) % 2;
            // Calculate x, y position for QR code
            const xQr = col * (qrCodeSize + marginX + columnGap) + columnGap + 16;
            const yQr = row * (qrCodeSize + marginY);
            // Calculate x, y position for Serial Number
            const xSerial = xQr - 16;
            const ySerial = yQr + qrCodeSize + 5;
            // Add QR code to PDF
            pdf.addImage(qrImage, 'PNG', xQr, yQr, qrCodeSize, qrCodeSize);
            // Add the Type style above the QR code
            pdf.text(`Type: ${dataExport[i].name}`, ['pv', 'pm'].includes(dataExport[i].type) ? xSerial + 8 : xSerial + 16, ySerial);
            // Add the Serial Number below the QR code
            pdf.text(`SN: ${dataExport[i].sn}`, xSerial + 5, ySerial + 8);
          }
          // If not the last page, add a new page
          if (page < totalPages - 1) {
            pdf.addPage();
          }
        }
        // Export PDF files
        let created_date_name = moment().format('DDMMYYYY');
        pdf.save(`QR_${created_date_name}.pdf`);
        this.selected = [];
      }
    },
    showDeviceKey(device_key) {
      const maxLength = 15;
      const deviceKey = device_key;
      return deviceKey.length > maxLength ? deviceKey.slice(0, maxLength) + '...' : deviceKey;
    },
    copyDeviceKey(device_key) {
      const input = document.createElement('input');
      input.value = device_key;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    },
    getCurrentMonth () {
      this.batchDate = moment().format("YYYY-MM");
      this.batchDateText = moment(this.batchDate).format("MM/YYYY");
    },
    getActiveCurrentMonth () {
      return this.batchDate === moment().format("YYYY-MM");
    },
    handleInputModel () {
      if (this.model) {
        this.showAutocomplete = true;
      } else {
        this.showAutocomplete = false;
      }
    },
    selectModelOption (option) {
      this.model = option;
      this.showAutocomplete = false;
    },
    closeAutocomplete () {
      setTimeout(() => {
        this.showAutocomplete = false;
      }, 200);
    },
    navigateOptions (event) {
      if (event.keyCode === 38) { // Arrow up
        event.preventDefault();
        if (this.highlightedIndex === 0) {
          this.highlightedIndex = this.filteredOptions.length - 1;
        } else {
          this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
        }
      } else if (event.keyCode === 40) { // Arrow down
        event.preventDefault();
        if (this.highlightedIndex === this.filteredOptions.length - 1) {
          this.highlightedIndex = 0;
        } else {
          this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.filteredOptions.length - 1);
        }
      }
    },
    selectHighlightedOption () {
      if (this.highlightedIndex !== -1) {
        this.selectModelOption(this.filteredOptions[this.highlightedIndex]);
      }
    },
    getLocalTimeZone () {
      this.$store.commit('setTimeZone', null);
      localStorage.removeItem('selectedTimeZone');
    },
    updateSubtypeList() {
      switch (this.deviceType) {
        case 'sc':
          this.subTypeList = [
            {
            title: 'eBox', 
            value: '01',
            }
          ]; 
          break;
        case 'pv':
          this.subTypeList = [
            {
            title: 'Single phase PV Inverter', 
            value: '01',
            },
            {
            title: 'Single phase PV Hybrid Inverter', 
            value: '02',
            },
            {
            title: 'Three Phase PV Inverter', 
            value: '03',
            },
            {
            title: 'Three Phase PV Hybrid Inverter', 
            value: '04',
            }
          ]; 
          break;
        case 'bess':
          this.subTypeList = [
            {
            title: '100Ah Energy Storage', 
            value: '01',
            },
            {
            title: '200Ah Energy Storage', 
            value: '02',
            }
          ]; 
          break;
        case 'evse':
          this.subTypeList = [
            {
            title: 'Single phase EVSE', 
            value: '01',
            },
            {
            title: 'Three phase EVSE', 
            value: '02',
            }
          ]; 
          break;
        case 'pm':
          this.subTypeList = [
            {
            title: 'Single phase Power Meter', 
            value: '01',
            },
            {
            title: 'Three phase Power Meter', 
            value: '02',
            }
          ]; 
          break;
      
        default:
        this.subTypeList = [];
      }

      if (this.subTypeList.length > 0) {
        this.subType = this.subTypeList[0].value;
      }
    }
  },
};
</script>
